#home-page {
    height: 79.5vh;
    padding-top: 10%;
    background-image: url("../../media/grey-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#left-panel {
    margin-top: 2%;
    margin-left: -40%;
    margin-bottom: -35%;
}

#profile-pic {
    padding: 0.5%;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#right-panel {
    margin-left: 50%;
    width: 45%;
    height: 33vh;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 5px solid black;
}

#section-title {
    font-size: 3.75vh;
}

#bio-content {
    margin-left: 5%;
    width: 90%;
    font-size: 2.75vh;
    line-height: 1.5;
}

#skills-panel-right {
    margin-top: 2%;
    margin-bottom: -20.25%;
    width: 23%;
    height: 35vh;
    margin-left: 50%;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 5px solid black;
}

#skills-bio {
    font-size: 2.75vh;
    line-height: 1.5;
}

#skills-panel-left {
    margin-top: 10%;
    width: 20%;
    height: 35vh;
    margin-left: 75%;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 5px solid black;
}

#skills-list {
    text-align: left;
    font-size: 2vh;
}

#skills-left {
    width: 45%;
    margin-left: 19%;
}

#skills-content-left {
    line-height: 1.5;
}

#skills-right {
    width: 55%;
    margin-left: 60%;
    margin-top: -45%;
}

#skills-content-right {
    line-height: 1.5;
}

a {
    color: white;
}

/* Media Queries */

@media (max-width: 1920px) {
    #home-page {
        height: 90vh;
    }

    #right-panel {
        height: 39vh;
    }

    #skills-panel-right {
        margin-top: 1%;
    }

    #skills-panel-left {
        margin-top: 2.5%;
    }
}

@media (max-width: 1820px) {
    #profile-pic {
        margin-top: 0%;
        margin-bottom: -2%;
    }

    #skills-panel-left {
        margin-top: 1.5%;
    }
}

@media (max-width: 1720px) {
    #profile-pic {
        margin-bottom: -3%;
    }

    #skills-panel-left {
        margin-top: 1%;
    }

    #skills-panel-right {
        height: 36vh;
        margin-bottom: -22.5%;
    }
    
    #skills-right {
        margin-top: -55%;
        margin-left: 65%;
    }
}

@media (max-width: 1620px) {
    #profile-pic {
        margin-top: 2%;
        margin-bottom: -6%;
    }

    #skills-panel-left {
        margin-top: 1%;
    }

    #skills-panel-right {
        height: 36vh;
        margin-bottom: -24%;
    }
    
    #skills-right {
        margin-top: -55%;
        margin-left: 65%;
    }
}

@media (max-width: 1520px) {
    #home-page {
        height: 103vh;
    }

    #profile-pic {
        margin-top: 4%;
        margin-bottom: -8%;
    }

    #right-panel {
        height: 45vh;
    }

    #skills-panel-left {
        margin-top: -3%;
        height: 40vh;
    }

    #skills-panel-right {
        height: 40vh;
        margin-bottom: -27%;
    }
    
    #skills-right {
        margin-top: -55%;
        margin-left: 65%;
    }
}

@media (max-width: 1420px) {
     #home-page {
        height: 103vh;
    }

    #profile-pic {
        margin-top: 5%;
        margin-bottom: -10%;
    }

    #right-panel {
        height: 45vh;
    }

    #skills-panel-left {
        margin-top: 0%;
        height: 35vh;
    }

    #skills-panel-right {
        height: 45vh;
        margin-bottom: -32.5%;
    }

    #skills-left {
        margin-left: 10%;
        width: 50%;
    }
    
    #skills-right {
        margin-top: -63%;
        margin-left: 65%;
    }
}

@media (max-width: 1320px) {
    #home-page {
        height: 105vh;
    }

    #profile-pic {
        margin-top: 6%;
        margin-bottom: -13%;
    }

    #right-panel {
        height: 45vh;
    }

    #skills-panel-left {
        height: 35vh;
        width: 22%;
    }

    #skills-panel-right {
        height: 49vh;
        margin-bottom: -37.75%;
    }

    #skills-left {
        margin-left: 10%;
        width: 55%;
    }
    
    #skills-right {
        margin-top: -62%;
        margin-left: 65%;
    }
}

@media (max-width: 1220px) {
    #home-page {
        height: 110vh;
    }

    #profile-pic {
        margin-top: 8%;
        margin-bottom: -18%;
        margin-left: -5%;
    }

    #right-panel {
        height: 48vh;
    }

    #skills-panel-left {
        margin-top: -3%;
        height: 43vh;
    }

    #skills-panel-right {
        width: 23%;
        height: 50vh;
        margin-bottom: -42%;
    }

    #skills-left {
        margin-left: 5%;
        width: 60%;
    }
    
    #skills-right {
        margin-top: -67%;
        margin-left: 62%;
    }
}

@media (max-width: 1120px) {
    #home-page {
        height: 110vh;
    }

    #profile-pic {
        margin-top: 9%;
        margin-bottom: -22%;
        margin-left: -7%;
    }

    #right-panel {
        height: 48vh;
        width: 48%;
        margin-left: 48%;
    }

    #skills-panel-left {
        margin-left: 74%;
        height: 38vh;
    }

    #skills-panel-right {
        width: 23%;
        height: 53vh;
        margin-bottom: -45.75%;
        margin-left: 48%;
    }

    #skills-left {
        margin-left: 5%;
        width: 60%;
    }
    
    #skills-right {
        margin-top: -67%;
        margin-left: 62%;
    }
}

@media (max-width: 1050px) {
    #home-page {
        height: 185vh;
    }

    #profile-pic {
        margin-top: 2%;
        margin-bottom: -2%;
        margin-left: 33%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 29%;
        height: 58vh;
    }

    #skills-panel-right {
        margin-top: 3%;
        margin-left: 12%;
        margin-bottom: -39%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 55%;
        margin-top: 0%;
        width: 40vh;
        height: 40vh;
    }

    #skills-left {
        margin-left: 15%;
        width: 60%;
    }

    #skills-right {
        margin-top: -45%;
        margin-left: 62%;
    }
}

@media (max-width: 950px) {
    #home-page {
        height: 225vh;
    }

    #profile-pic {
        margin-top: 5%;
        margin-left: 33.5%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 29%;
        height: 60vh;
    }

    #skills-panel-right {
        margin-left: 32%;
        margin-bottom: 2%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 32%;
    }
}

@media (max-width: 850px) {
    #home-page {
        height: 230vh;
    }

    #profile-pic {
        margin-top: 5%;
        margin-left: 32.5%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 29%;
        height: 65vh;
        width: 40vh;
    }

    #skills-panel-right {
        margin-left: 29%;
        margin-bottom: 2%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 29%;
    }
}

@media (max-width: 769px) {
    #home-page {
        height: 232vh;
    }

    #profile-pic {
        margin-top: 20%;
        margin-left: 30%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 20%;
        height: 60vh;
        width: 60%;
    }

    #skills-panel-right {
        margin-left: 25%;
        margin-bottom: 2%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 25%;
    }
}

@media (max-width: 650px) {
    #home-page {
        height: 232vh;
    }

    #profile-pic {
        margin-top: 20%;
        margin-left: 30%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 18%;
        margin-bottom: 4%;
        height: 60vh;
        width: 65%;
    }

    #skills-panel-right {
        margin-left: 19.5%;
        margin-bottom: 4%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 19.5%;
    }
}

@media (max-width: 550px) {
    #home-page {
        height: 232vh;
    }

    #profile-pic {
        margin-top: 27%;
        margin-left: 30%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 13%;
        margin-bottom: 4%;
        height: 60vh;
        width: 75%;
    }

    #skills-panel-right {
        margin-left: 14%;
        margin-bottom: 4%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 14%;
    }
}

@media (max-width: 450px) {
    #home-page {
        height: 250vh;
    }

    #profile-pic {
        margin-top: 37%;
        margin-left: 26%;
        width: 350px;
        height: 465px;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 10%;
        margin-bottom: 4%;
        height: 78vh;
        width: 75%;
    }

    #skills-panel-right {
        margin-left: 9%;
        margin-bottom: 4%;
        width: 35vh;
        height: 45vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 9%;
        width: 35vh;
        height: 40vh;
    }
}