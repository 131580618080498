#nav-header {
    color: white;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    position: fixed;
    top: 0;
    width: 100%;
    height: 12vh;
}
  
#nav-title {
    display: flex;
    padding-left: 3%;
    font-size: 4vh;
}
  
#nav-links {
    margin-top: -4.1%;
    margin-left: 85%;
    font-size: 4vh;
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-content: space-between;
}
#nav-link-home {
    color: white;
    padding-right: 25%;
    text-decoration: none;
}
#nav-link-home:hover {
    color: #b8b8b8;
}

#nav-link-projects {
    color: white;
    padding-right: 25%;
    text-decoration: none;
}

#nav-link-projects:hover {
    color: #b8b8b8;
}

#nav-link-contact {
    color: white;
    padding-right: 25%;
    text-decoration: none;
}
#nav-link-contact:hover {
    color: #b8b8b8;
}

/* Media Queries */

@media (max-width: 1420px) {
    #nav-links {
        margin-top: -5.5%;
    }
}

@media (max-width: 1201px) {
    #nav-links {
        margin-top: -7%;
    }
}

@media (max-width: 1100px) {
    #nav-links {
        margin-top: -7%;
    }
}

@media (max-width: 1050px) {
    #nav-links {
        margin-top: -7.5%;
    }
}

/* iPad Pro */
@media (max-width: 1024px) {
    @media (max-height: 1366px) {
        #nav-header {
            height: 16vh;
        }
        #nav-title {
            margin-left: 28%;
            margin-bottom: 7%;
        }
        #nav-links {
            margin-left: 70%;
        }
    }
}

@media (max-width: 1000px) { 
    #nav-links {
        margin-top: -6.5%;
    }
    
}

@media (max-width: 950px) {
    #nav-header {
        height: 15vh;
    }
    #nav-title {
        margin-left: 30%;
    }
    #nav-links {
        margin-top: -6%;
        margin-left: 65%;
    }
}

@media (max-width: 800px) {
    #nav-links {
        margin-left: 68%;
    }
}

@media (max-width: 769px) {
    #nav-header {
        height: 25vh;
    }
    #nav-title {
        margin-left: 25%;
        margin-bottom: 2.5%;
    }

    #nav-links {
        display: flex;
        flex-flow: column wrap;
        margin-left: 40%;
        margin-top: 2%;
    }
}

@media (max-width: 650px) {
    #nav-title {
        margin-left: 19%;
    }

    #nav-links {
        margin-left: 38%;
    }
}

@media (max-width: 550px) {
    #nav-title {
        margin-left: 15%;
    }

    #nav-links {
        margin-left: 35%;
    }
}

@media (max-width: 350px) {
    #nav-header {
        height: 23vh;
    }
    #nav-title {
        margin-left: 7%;
    }

    #nav-links {
        margin-left: 30%;
    }
}