#contact-page {
    height: 79.5vh;
    padding-top: 10%;
    background-image: url("../../media/grey-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#contact-title {
    margin-top: -2%;
    font-size: 5vh;
    -webkit-text-stroke: 1px black;
}

#contact-links {
    padding-top: 5%;
    display: flex;
    justify-content: center;
}

.icons {
    width: 50px;
    height: 50px;
    padding-right: 1%;
}

.icon-label {
    color: #590c0c;
    margin-top: 0.8%;
    padding-right: 1%;
}