.App {
  text-align: center;
  color: white;
  font-family: 'Denk One', sans-serif;
}

/* Scroll Bar Stuff */

/* width */
::-webkit-scrollbar {
  width: 18px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: black;
  box-shadow: inset 0 0 5px grey;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0,0,0);
  background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
  border-radius: 10px;
  transition: transform 5s ease;
}
/* Handle Hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0,0,0);
  background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(122,16,16,1) 19%, rgba(147,18,18,1) 34%, rgba(143,14,14,1) 45%, rgba(80,5,5,1) 61%, rgba(154,16,16,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
  transform: scale(1.5);
}
