#projects-page {
    height: 250vh;
    padding-top: 10%;
    background-image: url("../../media/grey-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#projects-title {
    margin-left: 23%;
    padding: 0% 3%;
    width: 50%;
    height: 20vh;
    background: rgb(0,0,0);
    background: linear-gradient(40deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 5px solid black;
}

h3 {
    font-size: 2.5vh;
}

#project-card1 {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 40vh;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#eco-soap-issue {
    width: 35%;
    margin-left: 10%;
}

.project-content1 {
    margin-top: 0.75%;
}

.project-content {
    margin-top: 2%;
}

.project-title {
    font-size: 4vh;
    color: white;
}

.project-bio {
    margin-top: 2.5%;
    margin-left: 5%;
    width: 40%;
    font-size: 2.1vh;
    line-height: 1.85;
}

.project-img {
    width: 500px;
    height: 244px;
    margin-top: -15%;
    margin-left: 48%;
    border: 5px solid black;
}

.project-img1 {
    width: 500px;
    height: 244px;
    margin-top: -18%;
    margin-left: 48%;
    border: 5px solid black;
}

#project-card2 {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 40vh;
    background: rgb(0,0,0);
    background: linear-gradient(40deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#project-card3 {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 40vh;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#project-img3{
    width: 500px;
    height: 244px;
    margin-top: -16%;
    margin-left: 48%;
    border: 5px solid black;
}

#project-card4 {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 40vh;
    background: rgb(0,0,0);
    background: linear-gradient(40deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#project-bio4 {
    margin-top: 2.7%;
    margin-left: 5%;
    width: 40%;
    font-size: 2.1vh;
    line-height: 1.85;
}

#project-img4 {
    width: 500px;
    height: 244px;
    margin-top: -18%;
    margin-left: 48%;
    border: 5px solid black;
}

#bottom-card {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 23vh;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#bottom-title {
    font-size: 4vh;
}

#bottom-bio {
    font-size: 2.5vh;
}

#github-link {
    padding: 0.75%;
    font-size: 2.5vh;
    color: white;
    background-color: black;
    border-radius: 7px;
}