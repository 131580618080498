body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  color: white;
  font-family: 'Denk One', sans-serif;
}

/* Scroll Bar Stuff */

/* width */
::-webkit-scrollbar {
  width: 18px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: black;
  box-shadow: inset 0 0 5px grey;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0,0,0);
  background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
  border-radius: 10px;
  -webkit-transition: transform 5s ease;
  transition: transform 5s ease;
}
/* Handle Hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0,0,0);
  background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(122,16,16,1) 19%, rgba(147,18,18,1) 34%, rgba(143,14,14,1) 45%, rgba(80,5,5,1) 61%, rgba(154,16,16,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
  transform: scale(1.5);
}

#nav-header {
    color: white;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    position: fixed;
    top: 0;
    width: 100%;
    height: 12vh;
}
  
#nav-title {
    display: flex;
    padding-left: 3%;
    font-size: 4vh;
}
  
#nav-links {
    margin-top: -4.1%;
    margin-left: 85%;
    font-size: 4vh;
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-content: space-between;
}
#nav-link-home {
    color: white;
    padding-right: 25%;
    text-decoration: none;
}
#nav-link-home:hover {
    color: #b8b8b8;
}

#nav-link-projects {
    color: white;
    padding-right: 25%;
    text-decoration: none;
}

#nav-link-projects:hover {
    color: #b8b8b8;
}

#nav-link-contact {
    color: white;
    padding-right: 25%;
    text-decoration: none;
}
#nav-link-contact:hover {
    color: #b8b8b8;
}

/* Media Queries */

@media (max-width: 1420px) {
    #nav-links {
        margin-top: -5.5%;
    }
}

@media (max-width: 1201px) {
    #nav-links {
        margin-top: -7%;
    }
}

@media (max-width: 1100px) {
    #nav-links {
        margin-top: -7%;
    }
}

@media (max-width: 1050px) {
    #nav-links {
        margin-top: -7.5%;
    }
}

/* iPad Pro */
@media (max-width: 1024px) {
    @media (max-height: 1366px) {
        #nav-header {
            height: 16vh;
        }
        #nav-title {
            margin-left: 28%;
            margin-bottom: 7%;
        }
        #nav-links {
            margin-left: 70%;
        }
    }
}

@media (max-width: 1000px) { 
    #nav-links {
        margin-top: -6.5%;
    }
    
}

@media (max-width: 950px) {
    #nav-header {
        height: 15vh;
    }
    #nav-title {
        margin-left: 30%;
    }
    #nav-links {
        margin-top: -6%;
        margin-left: 65%;
    }
}

@media (max-width: 800px) {
    #nav-links {
        margin-left: 68%;
    }
}

@media (max-width: 769px) {
    #nav-header {
        height: 25vh;
    }
    #nav-title {
        margin-left: 25%;
        margin-bottom: 2.5%;
    }

    #nav-links {
        display: flex;
        flex-flow: column wrap;
        margin-left: 40%;
        margin-top: 2%;
    }
}

@media (max-width: 650px) {
    #nav-title {
        margin-left: 19%;
    }

    #nav-links {
        margin-left: 38%;
    }
}

@media (max-width: 550px) {
    #nav-title {
        margin-left: 15%;
    }

    #nav-links {
        margin-left: 35%;
    }
}

@media (max-width: 350px) {
    #nav-header {
        height: 23vh;
    }
    #nav-title {
        margin-left: 7%;
    }

    #nav-links {
        margin-left: 30%;
    }
}
#home-page {
    height: 79.5vh;
    padding-top: 10%;
    background-image: url(/static/media/grey-background.64c04ff7.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#left-panel {
    margin-top: 2%;
    margin-left: -40%;
    margin-bottom: -35%;
}

#profile-pic {
    padding: 0.5%;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#right-panel {
    margin-left: 50%;
    width: 45%;
    height: 33vh;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 5px solid black;
}

#section-title {
    font-size: 3.75vh;
}

#bio-content {
    margin-left: 5%;
    width: 90%;
    font-size: 2.75vh;
    line-height: 1.5;
}

#skills-panel-right {
    margin-top: 2%;
    margin-bottom: -20.25%;
    width: 23%;
    height: 35vh;
    margin-left: 50%;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 5px solid black;
}

#skills-bio {
    font-size: 2.75vh;
    line-height: 1.5;
}

#skills-panel-left {
    margin-top: 10%;
    width: 20%;
    height: 35vh;
    margin-left: 75%;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 5px solid black;
}

#skills-list {
    text-align: left;
    font-size: 2vh;
}

#skills-left {
    width: 45%;
    margin-left: 19%;
}

#skills-content-left {
    line-height: 1.5;
}

#skills-right {
    width: 55%;
    margin-left: 60%;
    margin-top: -45%;
}

#skills-content-right {
    line-height: 1.5;
}

a {
    color: white;
}

/* Media Queries */

@media (max-width: 1920px) {
    #home-page {
        height: 90vh;
    }

    #right-panel {
        height: 39vh;
    }

    #skills-panel-right {
        margin-top: 1%;
    }

    #skills-panel-left {
        margin-top: 2.5%;
    }
}

@media (max-width: 1820px) {
    #profile-pic {
        margin-top: 0%;
        margin-bottom: -2%;
    }

    #skills-panel-left {
        margin-top: 1.5%;
    }
}

@media (max-width: 1720px) {
    #profile-pic {
        margin-bottom: -3%;
    }

    #skills-panel-left {
        margin-top: 1%;
    }

    #skills-panel-right {
        height: 36vh;
        margin-bottom: -22.5%;
    }
    
    #skills-right {
        margin-top: -55%;
        margin-left: 65%;
    }
}

@media (max-width: 1620px) {
    #profile-pic {
        margin-top: 2%;
        margin-bottom: -6%;
    }

    #skills-panel-left {
        margin-top: 1%;
    }

    #skills-panel-right {
        height: 36vh;
        margin-bottom: -24%;
    }
    
    #skills-right {
        margin-top: -55%;
        margin-left: 65%;
    }
}

@media (max-width: 1520px) {
    #home-page {
        height: 103vh;
    }

    #profile-pic {
        margin-top: 4%;
        margin-bottom: -8%;
    }

    #right-panel {
        height: 45vh;
    }

    #skills-panel-left {
        margin-top: -3%;
        height: 40vh;
    }

    #skills-panel-right {
        height: 40vh;
        margin-bottom: -27%;
    }
    
    #skills-right {
        margin-top: -55%;
        margin-left: 65%;
    }
}

@media (max-width: 1420px) {
     #home-page {
        height: 103vh;
    }

    #profile-pic {
        margin-top: 5%;
        margin-bottom: -10%;
    }

    #right-panel {
        height: 45vh;
    }

    #skills-panel-left {
        margin-top: 0%;
        height: 35vh;
    }

    #skills-panel-right {
        height: 45vh;
        margin-bottom: -32.5%;
    }

    #skills-left {
        margin-left: 10%;
        width: 50%;
    }
    
    #skills-right {
        margin-top: -63%;
        margin-left: 65%;
    }
}

@media (max-width: 1320px) {
    #home-page {
        height: 105vh;
    }

    #profile-pic {
        margin-top: 6%;
        margin-bottom: -13%;
    }

    #right-panel {
        height: 45vh;
    }

    #skills-panel-left {
        height: 35vh;
        width: 22%;
    }

    #skills-panel-right {
        height: 49vh;
        margin-bottom: -37.75%;
    }

    #skills-left {
        margin-left: 10%;
        width: 55%;
    }
    
    #skills-right {
        margin-top: -62%;
        margin-left: 65%;
    }
}

@media (max-width: 1220px) {
    #home-page {
        height: 110vh;
    }

    #profile-pic {
        margin-top: 8%;
        margin-bottom: -18%;
        margin-left: -5%;
    }

    #right-panel {
        height: 48vh;
    }

    #skills-panel-left {
        margin-top: -3%;
        height: 43vh;
    }

    #skills-panel-right {
        width: 23%;
        height: 50vh;
        margin-bottom: -42%;
    }

    #skills-left {
        margin-left: 5%;
        width: 60%;
    }
    
    #skills-right {
        margin-top: -67%;
        margin-left: 62%;
    }
}

@media (max-width: 1120px) {
    #home-page {
        height: 110vh;
    }

    #profile-pic {
        margin-top: 9%;
        margin-bottom: -22%;
        margin-left: -7%;
    }

    #right-panel {
        height: 48vh;
        width: 48%;
        margin-left: 48%;
    }

    #skills-panel-left {
        margin-left: 74%;
        height: 38vh;
    }

    #skills-panel-right {
        width: 23%;
        height: 53vh;
        margin-bottom: -45.75%;
        margin-left: 48%;
    }

    #skills-left {
        margin-left: 5%;
        width: 60%;
    }
    
    #skills-right {
        margin-top: -67%;
        margin-left: 62%;
    }
}

@media (max-width: 1050px) {
    #home-page {
        height: 185vh;
    }

    #profile-pic {
        margin-top: 2%;
        margin-bottom: -2%;
        margin-left: 33%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 29%;
        height: 58vh;
    }

    #skills-panel-right {
        margin-top: 3%;
        margin-left: 12%;
        margin-bottom: -39%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 55%;
        margin-top: 0%;
        width: 40vh;
        height: 40vh;
    }

    #skills-left {
        margin-left: 15%;
        width: 60%;
    }

    #skills-right {
        margin-top: -45%;
        margin-left: 62%;
    }
}

@media (max-width: 950px) {
    #home-page {
        height: 225vh;
    }

    #profile-pic {
        margin-top: 5%;
        margin-left: 33.5%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 29%;
        height: 60vh;
    }

    #skills-panel-right {
        margin-left: 32%;
        margin-bottom: 2%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 32%;
    }
}

@media (max-width: 850px) {
    #home-page {
        height: 230vh;
    }

    #profile-pic {
        margin-top: 5%;
        margin-left: 32.5%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 29%;
        height: 65vh;
        width: 40vh;
    }

    #skills-panel-right {
        margin-left: 29%;
        margin-bottom: 2%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 29%;
    }
}

@media (max-width: 769px) {
    #home-page {
        height: 232vh;
    }

    #profile-pic {
        margin-top: 20%;
        margin-left: 30%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 20%;
        height: 60vh;
        width: 60%;
    }

    #skills-panel-right {
        margin-left: 25%;
        margin-bottom: 2%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 25%;
    }
}

@media (max-width: 650px) {
    #home-page {
        height: 232vh;
    }

    #profile-pic {
        margin-top: 20%;
        margin-left: 30%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 18%;
        margin-bottom: 4%;
        height: 60vh;
        width: 65%;
    }

    #skills-panel-right {
        margin-left: 19.5%;
        margin-bottom: 4%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 19.5%;
    }
}

@media (max-width: 550px) {
    #home-page {
        height: 232vh;
    }

    #profile-pic {
        margin-top: 27%;
        margin-left: 30%;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 13%;
        margin-bottom: 4%;
        height: 60vh;
        width: 75%;
    }

    #skills-panel-right {
        margin-left: 14%;
        margin-bottom: 4%;
        width: 40vh;
        height: 40vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 14%;
    }
}

@media (max-width: 450px) {
    #home-page {
        height: 250vh;
    }

    #profile-pic {
        margin-top: 37%;
        margin-left: 26%;
        width: 350px;
        height: 465px;
    }

    #right-panel {
        margin-top: 40%;
        margin-left: 10%;
        margin-bottom: 4%;
        height: 78vh;
        width: 75%;
    }

    #skills-panel-right {
        margin-left: 9%;
        margin-bottom: 4%;
        width: 35vh;
        height: 45vh;
        padding: 0%;
    }

    #skills-panel-left {
        margin-left: 9%;
        width: 35vh;
        height: 40vh;
    }
}
#projects-page {
    height: 250vh;
    padding-top: 10%;
    background-image: url(/static/media/grey-background.64c04ff7.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#projects-title {
    margin-left: 23%;
    padding: 0% 3%;
    width: 50%;
    height: 20vh;
    background: rgb(0,0,0);
    background: linear-gradient(40deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 5px solid black;
}

h3 {
    font-size: 2.5vh;
}

#project-card1 {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 40vh;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#eco-soap-issue {
    width: 35%;
    margin-left: 10%;
}

.project-content1 {
    margin-top: 0.75%;
}

.project-content {
    margin-top: 2%;
}

.project-title {
    font-size: 4vh;
    color: white;
}

.project-bio {
    margin-top: 2.5%;
    margin-left: 5%;
    width: 40%;
    font-size: 2.1vh;
    line-height: 1.85;
}

.project-img {
    width: 500px;
    height: 244px;
    margin-top: -15%;
    margin-left: 48%;
    border: 5px solid black;
}

.project-img1 {
    width: 500px;
    height: 244px;
    margin-top: -18%;
    margin-left: 48%;
    border: 5px solid black;
}

#project-card2 {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 40vh;
    background: rgb(0,0,0);
    background: linear-gradient(40deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#project-card3 {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 40vh;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#project-img3{
    width: 500px;
    height: 244px;
    margin-top: -16%;
    margin-left: 48%;
    border: 5px solid black;
}

#project-card4 {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 40vh;
    background: rgb(0,0,0);
    background: linear-gradient(40deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#project-bio4 {
    margin-top: 2.7%;
    margin-left: 5%;
    width: 40%;
    font-size: 2.1vh;
    line-height: 1.85;
}

#project-img4 {
    width: 500px;
    height: 244px;
    margin-top: -18%;
    margin-left: 48%;
    border: 5px solid black;
}

#bottom-card {
    margin-top: 3%;
    margin-left: 12.5%;
    width: 75%;
    height: 23vh;
    background: rgb(0,0,0);
    background: linear-gradient(152deg, rgba(0,0,0,1) 0%, rgba(61,3,3,1) 10%, rgba(88,4,4,1) 19%, rgba(59,6,6,1) 34%, rgba(126,18,18,1) 45%, rgba(52,6,6,1) 61%, rgba(120,17,17,1) 81%, rgba(27,1,1,1) 93%, rgba(0,0,0,1) 100%);
    border: 7px solid black;
}

#bottom-title {
    font-size: 4vh;
}

#bottom-bio {
    font-size: 2.5vh;
}

#github-link {
    padding: 0.75%;
    font-size: 2.5vh;
    color: white;
    background-color: black;
    border-radius: 7px;
}
#contact-page {
    height: 79.5vh;
    padding-top: 10%;
    background-image: url(/static/media/grey-background.64c04ff7.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#contact-title {
    margin-top: -2%;
    font-size: 5vh;
    -webkit-text-stroke: 1px black;
}

#contact-links {
    padding-top: 5%;
    display: flex;
    justify-content: center;
}

.icons {
    width: 50px;
    height: 50px;
    padding-right: 1%;
}

.icon-label {
    color: #590c0c;
    margin-top: 0.8%;
    padding-right: 1%;
}
